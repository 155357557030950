import * as yup from "yup";

import {
    validationTextField,
    validationEmailField,
    validationPhoneNumber,
    validationOldPasswordNotRequired,
    validationConfirmNewPasswordL,
  } from "./defaultValidations";


  export default yup.object({
    firstName: validationTextField("First Name", 3, 127),
    lastName: validationTextField("Last Name", 3, 127),
    email: validationEmailField("Email", 3, 127),
    mobile: validationPhoneNumber("Mobile", 3, 127),
    password: validationOldPasswordNotRequired("Password", 8, 127),
    confirmPassword: validationConfirmNewPasswordL("Confirm Password"),
    status: validationTextField("Status", 3, 127),
  });
  
